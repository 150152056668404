body > .popup .pricing-slider-window {
  width: 512px;
  padding: 32px 30px 0;
}
body > .popup .pricing-slider-window .special_offer-spacing {
  margin: 32px 0;
}
body > .popup .pricing-slider-window .content p.title {
  margin: -8px 0 11px;
}
body > .popup .pricing-slider-window .content p.modal-description {
  font-size: 14px;
  line-height: 1.72;
  color: #767676;
  padding-right: 5px;
}
body > .popup .pricing-slider-window .content p.modal-description span {
  display: block;
  margin-bottom: 8px;
}
body > .popup .pricing-slider-window .content .pricing {
  background-color: #fbfafa;
  margin: 0 -30px;
  padding: 25px 30px;
}
body > .popup .pricing-slider-window .content .pricing__title {
  font-size: 20px;
  line-height: 1.4;
  margin: 0 0 17px;
  letter-spacing: -0.1px;
}
body > .popup .pricing-slider-window .content .pricing__users-for-now {
  margin: 0 0 12px;
  letter-spacing: -0.1px;
}
body > .popup .pricing-slider-window .content .pricing__monthly-fee {
  font-size: 24px;
  line-height: 1.17;
  color: #767676;
  letter-spacing: -0.5px;
}
body > .popup .pricing-slider-window .content .pricing__monthly-fee span {
  display: block;
  font-size: 12px;
  line-height: 2.33;
  color: #767676;
  text-transform: uppercase;
  letter-spacing: 0.4px;
}
body > .popup .pricing-slider-window .content .pricing__monthly-fee .please-contact {
  height: 33px;
  font-size: 13px;
  font-weight: bold;
}
body > .popup .pricing-slider-window .content .pricing__monthly-fee .please-contact a {
  text-decoration: none;
  color: #448af1;
}
body > .popup .pricing-slider-window .content .pricing__monthly-fee .please-contact a:hover {
  text-decoration: underline;
}
body > .popup .pricing-slider-window .content .pricing__premium {
  font-size: 14px;
  line-height: 1.71;
  text-align: left;
  color: #767676;
  margin: 0 0 18px 0;
}
body > .popup .pricing-slider-window .content .pricing__premium a {
  text-decoration: none;
  color: #448af1;
}
body > .popup .pricing-slider-window .content .pricing__premium a:hover {
  text-decoration: underline;
}
body > .popup .pricing-slider-window .content .pricing__button {
  padding: 0;
  margin: 0;
}
body > .popup .pricing-slider-window .content .pricing__button .contact-us {
  width: 120px;
  height: 36px;
  line-height: 36px;
  display: inline-block;
  white-space: nowrap;
  padding: 0;
}
body > .popup .pricing-slider-window .content .pricing-footer {
  margin: 30px 0 20px;
}
body > .popup .pricing-slider-window .content .pricing-footer__column {
  display: inline-block;
  padding-right: 45px;
  vertical-align: top;
  font-size: 32px;
  letter-spacing: -0.5px;
}
body > .popup .pricing-slider-window .content .pricing-footer__column span {
  display: block;
  font-size: 12px;
  line-height: 1.5;
  color: #767676;
  text-transform: uppercase;
  letter-spacing: 0.4px;
}
/*------------------------------
  rzslider modifications start
------------------------------*/
.pricing__slider {
  width: 100%;
  height: 65px;
  margin-bottom: 14px;
  padding-top: 5px;
}
.pricing__slider .rzslider {
  height: 10px;
  margin: 0;
}
.pricing__slider .rz-bar-wrapper {
  height: 10px;
  padding-top: 0;
  margin-top: 0;
}
.pricing__slider .rz-bar {
  height: 10px;
  background: #f8f7f7;
  border-radius: 10px;
  box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.2);
}
.pricing__slider .rz-bar.rz-selection {
  border-radius: 10px;
}
.pricing__slider .rz-pointer {
  top: -12px;
  width: 34px;
  height: 34px;
  box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 50%;
}
.pricing__slider .rz-pointer:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./images/four-vertical-lines.svg');
  background-repeat: no-repeat;
  background-size: 10px 12px;
  background-position: 50% 50%;
}
.pricing__slider .rz-pointer:hover {
  box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
  border: none;
}
.pricing__slider .rz-pointer:active {
  box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
  border: none;
}
.pricing__slider .rz-bubble {
  color: var(--black);
  bottom: -36px;
}
.pricing__slider .rz-bubble.rz-limit {
  color: var(--black);
}
/* rzsider modifications end */
