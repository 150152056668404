art-ref-suggest div {
  position: relative;
}
art-ref-suggest div input {
  padding: 15px 0 15px 15px;
}
art-ref-suggest div .popup-ref {
  position: absolute;
  top: 100%;
  left: 0;
  max-width: 100%;
  border-radius: 7px;
  background-color: var(--white);
  box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px rgba(0, 0, 0, 0.12);
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 10;
  -webkit-user-select: none;
  user-select: none;
  max-height: 0;
  transition: max-height 200ms cubic-bezier(0.2, 0.7, 0.5, 1), opacity 0ms 200ms;
  pointer-events: none;
  opacity: 0;
}
art-ref-suggest div .popup-ref.open {
  max-height: 300px;
  pointer-events: auto;
  opacity: 1;
  transition: max-height 200ms cubic-bezier(0.2, 0.7, 0.5, 1), opacity 0ms 0ms;
}
art-ref-suggest div .popup-ref ul {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
}
art-ref-suggest div .popup-ref ul li {
  display: block;
  height: 40px;
  line-height: 40px;
  padding: 0 16px;
  cursor: pointer;
  transition: background-color 100ms;
  white-space: nowrap;
}
art-ref-suggest div .popup-ref ul li:hover {
  background-color: var(--greyLight20) !important;
}
art-ref-suggest div .popup-ref ul li.act {
  background-color: var(--greyLight20);
}
art-ref-suggest div .popup-ref ul li .text {
  display: inline-block;
  font-size: 16px;
  color: var(--black);
  margin: 0 4px 0 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}
art-ref-suggest div .popup-ref ul li .block {
  display: inline-block;
  font-size: 12px;
  color: #767676;
  padding: 0 4px;
  line-height: 16px;
  height: 16px;
  border-radius: 3px;
  background-color: #e1e1e1;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}
art-ref-suggest div .popup-ref ul:hover li.act {
  background-color: transparent;
}
