.art-textarea-expand {
  position: relative;
  border-radius: 7px;
  background-color: rgba(0, 0, 0, 0.01);
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px rgba(0, 0, 0, 0.2);
  padding: 3px 16px;
  font-size: 16px;
  line-height: 1.75;
  color: var(--black);
  min-height: 36px;
  outline: 1px solid rgba(0, 0, 0, 0);
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-user-select: auto;
  user-select: all;
}
.art-textarea-expand.error {
  box-shadow: inset 0 1px 3px 0 rgba(81, 0, 0, 0.07);
  border: solid 1px #e9604a;
  background-color: #fff5f4;
}
.art-textarea-expand.error::before {
  color: #e9604a;
}
.art-textarea-expand::before {
  display: inline-block;
  pointer-events: none;
}
.art-textarea-expand.show-discount::after {
  content: attr(discount);
  position: absolute;
  right: 0;
  bottom: 0;
  width: 20px;
  height: 14px;
  color: var(--green);
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  z-index: 10;
  opacity: 0;
  transition: opacity 100ms;
}
.art-textarea-expand.show-discount:focus::after {
  opacity: 1;
}
.art-textarea-expand.show-discount.show-discount-red::after {
  color: #e9604a;
}
.art-textarea-expand > span {
  border-radius: 20px;
  background-color: #906d60;
  padding: 1px 3px 3px;
  color: var(--white);
  margin: 0 1px;
  white-space: nowrap;
  vertical-align: baseline;
  line-height: normal;
}
.art-textarea-expand > span.current {
  background-color: transparent !important;
  color: inherit;
}
.art-textarea-expand > span.system {
  background-color: #9e80bd;
}
ul.art-textarea-expand-popup {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--zindex-modal);
  min-width: 200px;
  max-width: 300px;
  width: auto;
  max-height: 294px;
  overflow-y: auto;
  overflow-x: hidden;
  height: auto;
  border-radius: 3px;
  background-color: var(--white);
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px rgba(0, 0, 0, 0.15);
  padding: 2px 0;
}
ul.art-textarea-expand-popup.act {
  display: block;
}
ul.art-textarea-expand-popup li {
  cursor: pointer;
  padding: 5px;
  white-space: nowrap;
  max-width: unset;
  min-width: auto;
}
ul.art-textarea-expand-popup li > span {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding: 0px 18px;
  height: 26px;
  line-height: 26px;
  background-color: #906d60;
  font-size: 16px;
  color: var(--white);
  border-radius: 13px;
  max-width: 270px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
ul.art-textarea-expand-popup li > span::before {
  content: '{{';
  font-size: 16px;
  position: absolute;
  left: 4px;
  top: -1px;
  color: #e0995d;
}
ul.art-textarea-expand-popup li > span::after {
  content: '}}';
  font-size: 16px;
  position: absolute;
  right: 4px;
  top: -1px;
  color: #e0995d;
}
ul.art-textarea-expand-popup li:hover,
ul.art-textarea-expand-popup li.act {
  background-color: var(--greyLight20);
}
ul.art-textarea-expand-popup li.system > span {
  background-color: #9e80bd;
}
ul.art-textarea-expand-popup li.system > span:before,
ul.art-textarea-expand-popup li.system > span:after {
  color: #e0e0e0;
}
