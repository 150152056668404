.blocks-suggest {
  position: relative;
  height: 36px;
  border-radius: 7px;
}
.blocks-suggest br {
  content: '';
}
.blocks-suggest .bs-content {
  width: 100%;
  padding: 7px 8px;
  height: 44px;
  margin: -4px 0;
  overflow: hidden;
  cursor: text;
  white-space: nowrap;
}
.blocks-suggest .bs-content .blocks-list {
  display: inline-block;
  vertical-align: top;
}
.blocks-suggest .bs-content .blocks-list .block-item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  height: 28px;
  border-radius: 7px;
  background-color: #e0e0e0;
  border: solid 1px rgba(0, 0, 0, 0.21);
  padding: 0;
  margin: 0 8px 0 0;
  cursor: default;
}
.blocks-suggest .bs-content .blocks-list .block-item:last-child {
  margin: 0;
}
.blocks-suggest .bs-content .blocks-list .block-item::after {
  content: ',';
  position: absolute;
  top: -1px;
  right: -8px;
  width: 16px;
  height: 28px;
  line-height: 28px;
  font-size: 16px;
  padding: 0 0 0 11px;
  color: #aaaaaa;
}
.blocks-suggest .bs-content .blocks-list .block-item:last-child::after {
  display: none;
}
.blocks-suggest .bs-content .blocks-list .block-item .block-title {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  line-height: 28px;
  height: 28px;
  outline: none;
  padding: 0 7px;
  font-size: 16px;
  color: var(--black);
  min-width: 10px;
  -webkit-user-select: none !important;
  user-select: none !important;
  cursor: pointer;
}
.blocks-suggest .bs-content .blocks-list .block-item .trash-popup {
  left: auto;
  right: -8px;
  top: -8px;
}
.blocks-suggest .bs-content .blocks-list .block-item:hover .trash-popup {
  transform: scale(1, 1);
  opacity: 1;
}
.blocks-suggest .bs-content .bs-input {
  display: inline-block;
  vertical-align: top;
  padding: 0 0 0 4px;
  line-height: 28px;
  height: 28px;
  min-width: 140px;
  outline: none;
  -webkit-user-select: auto !important;
  user-select: auto !important;
}
.blocks-suggest .bs-content .bs-input br {
  content: '';
}
.blocks-suggest .bs-content .bs-placeholder {
  position: absolute;
  top: 3px;
  left: 3px;
  padding: 0 0 0 8px;
  font-size: 16px;
  line-height: 1.75;
  color: var(--grey);
  white-space: nowrap;
  pointer-events: none;
}
.blocks-suggest .bs-popup {
  position: absolute;
  top: 35px;
  cursor: auto;
  z-index: var(--zindex-dropdown);
  min-width: 220px;
  height: auto;
  border-radius: 7px;
  background-color: var(--white);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.13);
  border: solid 1px rgba(0, 0, 0, 0.09);
  max-height: 1px;
  max-width: 1px;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  transition: opacity cubic-bezier(0.2, 0.7, 0.5, 1) 200ms, max-height cubic-bezier(0.2, 0.7, 0.5, 1) 200ms, max-width 0ms 200ms;
  color: var(--black);
}
.blocks-suggest .bs-popup.open {
  max-height: 370px;
  max-width: 500px;
  opacity: 1;
  pointer-events: all;
  transition: opacity cubic-bezier(0.2, 0.7, 0.5, 1) 200ms, max-height cubic-bezier(0.2, 0.7, 0.5, 1) 200ms, max-width 0ms 0ms;
}
.blocks-suggest .bs-popup .add-block {
  position: relative;
  height: 41px;
  line-height: 40px;
  border-bottom: 1px solid #e0e0e0;
  font-size: 16px;
  padding: 0 10px 0 34px;
  cursor: pointer;
  white-space: nowrap;
}
.blocks-suggest .bs-popup .add-block.void {
  border-bottom: none;
  height: 40px;
  border-radius: 7px;
}
.blocks-suggest .bs-popup .add-block::before {
  content: '';
  top: 12px;
  left: 11px;
  position: absolute;
  opacity: 0.3;
  width: 16px;
  height: 16px;
  background: url('../images/ic_add_small.svg') center center no-repeat;
}
.blocks-suggest .bs-popup .add-block.act {
  background-color: #f1f2f2;
}
.blocks-suggest .bs-popup .add-block.act::before {
  opacity: 1;
}
.blocks-suggest .bs-popup .add-block span {
  display: inline-block;
  max-width: 138px;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: bottom;
  text-overflow: ellipsis;
}
.blocks-suggest .bs-popup ul {
  padding: 0;
  margin: 0;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 327px;
}
.blocks-suggest .bs-popup ul li {
  margin: 0;
  white-space: nowrap;
}
.blocks-suggest .bs-popup ul li.group-title {
  font-size: 13px;
  line-height: 24px;
  color: #767676;
  padding: 8px 0 0 16px;
  pointer-events: none;
}
.blocks-suggest .bs-popup ul li.bs-item {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  color: var(--black);
  padding: 0 16px 0 28px;
  cursor: pointer;
}
.blocks-suggest .bs-popup ul li.bs-item.act {
  background-color: #f1f2f2;
}
.blocks-suggest .bs-popup ul li.group-title,
.blocks-suggest .bs-popup ul li.bs-item {
  max-width: 290px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.blocks-suggest .bs-popup ul li.group-title span,
.blocks-suggest .bs-popup ul li.bs-item span {
  display: inline-block;
  height: 18px;
  line-height: 18px;
  border-radius: 3px;
  background-color: #ffe287;
  padding: 0 1px;
  margin: 0 -1px;
}
