.popup.clone-bot .back {
  opacity: 0.8;
  background-color: #252f33;
}
.popup.clone-bot .window {
  overflow: visible;
  min-height: 161px;
  padding: 20px 24px;
  border-radius: 7px;
  width: 320px;
}
.popup.clone-bot .window .content .title {
  font-size: 12px;
  line-height: 18px;
  color: #666;
  margin-bottom: 6px;
  font-weight: normal;
}
.popup.clone-bot .window .footer {
  border: none;
  height: auto;
  line-height: normal;
  position: relative;
  padding-right: 0;
  display: flex;
  justify-content: space-between;
}
.popup.clone-bot .window .success-state {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.popup.clone-bot .window .success-state .success-state__success-icon:after {
  content: '';
  width: 36px;
  height: 36px;
  background-image: url('../../../assets2/images/ic_ok.svg');
  position: relative;
  display: block;
  left: 4px;
  top: 4px;
  background-size: 36px 36px;
}
.popup.clone-bot .window .success-state .success-state__success-icon {
  width: 48px;
  height: 48px;
  border: solid 2px #44c196;
  border-radius: 100%;
  margin: 12px 0 16px;
}
.popup.clone-bot .window .success-state .success-state__success-text {
  font-size: 18px;
  line-height: 1.56;
  color: #3c3f40;
  text-align: center;
}
