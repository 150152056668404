section.settings .error-container,
.tos .error-container {
  background-color: rgba(233, 96, 74, 0.1);
  padding: 0 24px 0 48px;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
section.settings .error-container .error-message-wrapper,
.tos .error-container .error-message-wrapper {
  display: inline-block;
  margin: 10px 0;
}
section.settings .error-container .error-message-wrapper .error-logo,
.tos .error-container .error-message-wrapper .error-logo {
  background: url('../images/avatar_chatbot_red_eye.svg') center center no-repeat;
  position: absolute;
  content: '';
  width: 16px;
  height: 16px;
  left: 24px;
  top: 50%;
  transform: translateY(-50%);
}
section.settings .error-container .error-message-wrapper .error-message,
.tos .error-container .error-message-wrapper .error-message {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #e9604a;
  line-height: 1.43;
}
section.settings .error-container .error-message-wrapper .error-message.help,
.tos .error-container .error-message-wrapper .error-message.help {
  color: var(--grey);
}
section.settings .error-container .error-message-wrapper .error-message a,
.tos .error-container .error-message-wrapper .error-message a {
  color: var(--blue);
  text-decoration: none;
  cursor: pointer;
}
section.settings .error-container .error-message-wrapper .error-message a:hover,
.tos .error-container .error-message-wrapper .error-message a:hover {
  text-decoration: underline;
}
section.settings .error-container .button-ver2.orange-rounded,
.tos .error-container .button-ver2.orange-rounded {
  display: inline-block;
  margin: 0 16px 0 14px;
  flex-shrink: 0;
  height: 36px;
  min-width: 172px;
  line-height: 36px;
  font-size: 14px;
  font-weight: 300;
  white-space: nowrap;
  border-radius: 12px;
}
