.attr-selector {
  -webkit-user-select: none;
  user-select: none;
}
.attr-selector.error .sk {
  color: #e9604a !important;
}
.attr-selector.cf-input {
  height: 36px;
  border-radius: 7px;
  padding: 3px 5px;
  font-size: 16px;
  display: inline-block;
  vertical-align: bottom;
  width: 210px;
  position: relative;
}
.attr-selector.cf-input .cvp-input-box {
  display: inline-block;
  position: relative;
  height: 26px;
  line-height: 26px;
  max-width: 100%;
}
.attr-selector.cf-input .cvp-input-box .trash-popup {
  display: none;
  left: auto;
  right: -6px;
}
.attr-selector.cf-input .cvp-input-box:hover .trash-popup {
  opacity: 1;
  transform: scale(1, 1);
}
.attr-selector.cf-input .cvp-input-box .sk {
  position: absolute;
  top: 0;
  font-size: 16px;
  color: var(--black);
  transition: color 100ms;
  pointer-events: none;
}
.attr-selector.cf-input .cvp-input-box .sk.left {
  left: 8px;
}
.attr-selector.cf-input .cvp-input-box .sk.right {
  right: 8px;
}
.attr-selector.cf-input .cvp-input-box .cvp-hover {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.attr-selector.cf-input .cvp-input-box .cvp-hover.system {
  pointer-events: all;
}
.attr-selector.cf-input .cvp-input-box .cvp-input {
  outline: none;
  z-index: -1;
  display: inline-block;
  padding: 0 24px;
  border-radius: 13px;
  -webkit-user-select: auto;
  user-select: all;
  background-color: rgba(144, 109, 96, 0);
  transition: background-color 0ms, color 0ms;
  color: var(--grey);
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  margin: 1px 0 0;
}
.attr-selector.cf-input .cvp-input-box .cvp-input.on-focus-now {
  text-overflow: inherit;
}
.attr-selector.cf-input .cvp-input-box .cvp-input br {
  content: '';
}
.attr-selector.cf-input .cvp-input-box .cvp-input:not(:empty) {
  background-color: #906d60;
  color: var(--white);
  font-size: 16px;
  transition: background-color 100ms, color 100ms;
}
.attr-selector.cf-input .cvp-input-box .cvp-input:not(:empty) ~ .sk {
  font-size: 16px;
  color: #e0995d;
}
.attr-selector.cf-input .cvp-input-box .cvp-input:not(:empty) ~ .trash-popup {
  display: block;
}
.attr-selector.cf-input .cvp-input-box .cvp-hover.system + .cvp-input {
  background-color: #9e80bd;
}
.attr-selector.cf-input .cvp-input-box .cvp-hover.system + .cvp-input ~ .sk {
  color: #e0e0e0;
}
.attr-selector .svp-popup {
  position: absolute;
  border-radius: 7px;
  background-color: var(--white);
  border: solid 1px rgba(0, 0, 0, 0.13);
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
  z-index: 9;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 0;
  opacity: 0;
  pointer-events: none;
  left: 0;
  min-width: 210px;
}
.attr-selector .svp-popup.open {
  max-height: 267px;
  opacity: 1;
  pointer-events: all;
}
.attr-selector .svp-popup ul {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  min-width: initial;
}
.attr-selector .svp-popup ul li {
  display: flex;
  height: 48px;
  line-height: 47px;
  margin: 0;
  cursor: pointer;
  background-color: var(--white);
  order: 2;
  justify-content: space-between;
}
.attr-selector .svp-popup ul li:last-child {
  border-radius: 0 0 3px 0;
}
.attr-selector .svp-popup ul li.act {
  background-color: #f3f3f3;
}
.attr-selector .svp-popup ul li > span {
  display: table-cell;
  padding: 0 5px;
}
.attr-selector .svp-popup ul li > span.name span {
  position: relative;
  display: inline-block;
  height: 26px;
  border-radius: 13px;
  background-color: #906d60;
  padding: 0 22px;
  font-size: 16px;
  line-height: 26px;
  color: var(--white);
  max-width: 218px;
  overflow: hidden;
  margin-top: 11px;
  text-overflow: ellipsis;
}
.attr-selector .svp-popup ul li > span.name span::before {
  content: '{{';
  font-size: 16px;
  position: absolute;
  left: 8px;
  top: -1px;
  color: #e0995d;
}
.attr-selector .svp-popup ul li > span.name span::after {
  content: '}}';
  font-size: 16px;
  position: absolute;
  right: 8px;
  top: -1px;
  color: #e0995d;
}
.attr-selector .svp-popup ul li > span.name.noresponse span {
  background-color: #c3c3c3;
}
.attr-selector .svp-popup ul li > span.name.system span {
  background-color: #9e80bd;
}
.attr-selector .svp-popup ul li > span.name.system span:before,
.attr-selector .svp-popup ul li > span.name.system span:after {
  color: #e0e0e0;
}
.attr-selector .svp-popup ul li > span.stat {
  color: #b5b5b5;
  font-size: 14px;
  padding: 0 12px 0 0;
}
.attr-selector .svp-popup ul li.firstValue {
  order: 1;
}
/*
  style editable div like input
*/
.attr-selector.cf-input .cvp-input-box .cvp-input-box__design-input-wrapper {
  position: relative;
  display: inline-block;
  max-width: 100%;
  height: 26px;
  color: var(--grey);
  background-color: transparent;
  margin: 1px 0 0;
  padding: 0 21px;
  border-radius: 13px;
  transition: background-color 0ms, color 0ms;
}
.attr-selector.cf-input .cvp-input-box .cvp-input-box__design-input-wrapper .cvp-input {
  background-color: transparent;
  margin: 0;
  padding: 0 3px;
  border-radius: 0;
}
.attr-selector.cf-input .cvp-input-box .cvp-input-box__design-input-wrapper .sk {
  top: -1px;
}
/*
  brown
*/
.attr-selector.cf-input .cvp-input-box .cvp-input-box__design-input-wrapper.active {
  color: var(--white);
  background-color: #906d60;
  transition: background-color 100ms, color 100ms;
}
/*
  purple
*/
.attr-selector.cf-input .cvp-input-box .cvp-hover.system + .cvp-input-box__design-input-wrapper.active {
  background-color: #9e80bd;
}
.attr-selector.cf-input .cvp-input-box .cvp-hover.system + .cvp-input-box__design-input-wrapper.active .sk {
  color: #e0e0e0;
}
