.popup.disconnect-analytics {
  overflow: auto;
}
.popup.disconnect-analytics .scroll-box div.window {
  width: 434px;
  overflow: visible;
  padding: 24px;
  top: 50%;
}
.popup.disconnect-analytics .scroll-box div.window .content .header-title {
  font-size: 18px;
  color: var(--black);
  margin-bottom: 12px;
}
.popup.disconnect-analytics .scroll-box div.window .content .warn-text {
  font-size: 14px;
  line-height: 1.71;
  color: #e9604a;
  margin-bottom: 24px;
}
.popup.disconnect-analytics .scroll-box div.window .content .disconnect-steps {
  list-style: none;
  margin: 0 0 24px 0;
  padding: 0;
}
.popup.disconnect-analytics .scroll-box div.window .content .disconnect-steps .step {
  font-size: 14px;
  line-height: 2;
  color: #3c3f40;
}
.popup.disconnect-analytics .scroll-box div.window .content .disconnect-steps .step .link {
  color: #3672ee;
  text-decoration: none;
  cursor: pointer;
}
.popup.disconnect-analytics .scroll-box div.window .content .disconnect-steps .step + .step {
  margin-top: 12px;
}
.popup.disconnect-analytics .scroll-box div.window .content .footer-disconnect {
  background-color: var(--greyLight20);
  margin: 0 -24px -24px -24px;
  padding: 16px 24px 24px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}
.popup.disconnect-analytics .scroll-box div.window .content .footer-disconnect .footer-text {
  font-size: 14px;
  line-height: 1.57;
  color: #3c3f40;
  margin-bottom: 12px;
}
.popup.disconnect-analytics .scroll-box div.window .content .footer-disconnect .disconnect-btn {
  display: inline-block;
}
.popup.disconnect-analytics .scroll-box div.window .content .capitalize {
  text-transform: capitalize;
}
