.art-switcher {
  position: relative;
  cursor: pointer;
  width: 36px;
  height: 18px;
  border-radius: 9px;
  background-color: #e0e0e0;
  box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.07);
}
.art-switcher::before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background-color: var(--white);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}
.art-switcher.on {
  background-color: var(--green);
}
.art-switcher.on::before {
  left: 20px;
}
.art-switcher.anim {
  transition: background-color cubic-bezier(0.2, 0.7, 0.5, 1) 100ms;
}
.art-switcher.anim::before {
  transition: left cubic-bezier(0.2, 0.7, 0.5, 1) 100ms;
}
.art-switcher.disabled {
  pointer-events: none;
  background-color: var(--greyDark);
}
