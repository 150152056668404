div.localization .sub-title {
  font-size: 16px;
  line-height: 1.5;
  color: var(--black);
  cursor: pointer;
  margin: 32px 0 0 0;
  font-weight: bold;
  display: inline-block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}
div.localization .sub-title:after {
  content: '';
  display: inline-block;
  width: 24px;
  height: 24px;
  top: 7px;
  position: relative;
  background-image: url('../../assets2/images/ic_dropdown.svg');
  transform: rotate(-90deg);
}
div.localization .opened:after {
  transform: rotate(0deg);
}
div.localization .header-title {
  float: left;
  width: 220px;
  height: 24px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71;
  color: #767676;
  margin: 0 0 4px 0;
}
div.localization .header:after {
  border: 1px solid #dfdddd;
  content: '';
  display: block;
  clear: both;
}
div.localization .main {
  display: block;
  margin-top: 16px;
}
div.localization table td {
  width: 410px;
  padding: 24px 0 0 0;
}
div.localization .phrase-title {
  font-size: 15px;
  color: var(--black);
  margin: 0;
  padding: 0 5px 0 0;
}
div.localization .phrase-description {
  font-size: 12px;
  color: #767676;
  margin: 2px 0;
}
div.localization .caption {
  width: 410px;
  height: 36px;
  border-radius: 3px;
  padding: 6px 16px 6px 16px;
  background-color: var(--white);
  box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px rgba(0, 0, 0, 0.13);
}
