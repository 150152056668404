body > .popup.popup_uni-dropdown {
  width: auto;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.04), 0 16px 40px 0 rgba(0, 0, 0, 0.08);
  overflow: hidden;
  outline: none;
  opacity: 0;
  transition: opacity 0.2s ease;
}
body > .popup.popup_uni-dropdown.active {
  opacity: 1;
}
body > .popup.popup_uni-dropdown .back {
  background: transparent;
  background-color: transparent;
}
body > .popup_uni-dropdown .uni-dropdown {
  display: block;
  width: 100%;
  min-width: 160px;
  max-width: 200px;
  position: relative;
  z-index: 1;
  background-color: var(--white);
}
.uni-dropdown ul {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}
/*
  list item
*/
.uni-dropdown ul > li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.2s ease;
}
.uni-dropdown ul > li:hover {
  background-color: var(--greyLight30);
}
/*
  list item paragraph
*/
.uni-dropdown ul > li p {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 36px;
  color: var(--black);
  font-size: 13px;
  line-height: 1.23;
  text-align: center;
  margin: 0;
  padding: 0;
}
.uni-dropdown ul > li:not(:last-child) p {
  border-bottom: 1px solid #f2f1f1;
}
.uni-dropdown ul > li:hover p {
  color: var(--black);
}
