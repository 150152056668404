.popup.wizard-payments {
  overflow: auto;
}
.popup.wizard-payments .scroll-box {
  overflow: auto;
  position: absolute;
  z-index: 10;
  padding: 40px;
}
.popup.wizard-payments .scroll-box div.window {
  width: 660px;
  height: auto !important;
  padding-bottom: 69px !important;
  min-height: 390px;
}
.popup.wizard-payments .scroll-box div.window .wizard-content.first {
  margin-bottom: -69px;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state {
  position: relative;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state > .title {
  font-size: 16px;
  line-height: 1.75;
  color: var(--black);
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state a {
  color: #448af1;
  text-decoration: none;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state a:hover {
  text-decoration: underline;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state p {
  font-size: 16px;
  line-height: 1.75;
  color: var(--black);
  margin: 0 0 16px;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state p:last-child {
  margin: 0;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state p i {
  color: #666666;
  font-style: normal;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state p .req {
  color: #e9604a;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state .note {
  position: relative;
  margin: -8px 0 24px;
  border-radius: 7px;
  background-color: var(--greyLight20);
  padding: 6px 12px 6px 22px;
  font-size: 14px;
  line-height: 1.71;
  color: #666666;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state .note::before {
  content: '*';
  position: absolute;
  top: 6px;
  left: 12px;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-0 {
  position: relative;
  padding: 0;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-0 .p-var {
  padding: 16px 35px;
  margin: 0 -30px;
  min-height: 338px;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-0 .p-var .title {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.56;
  color: var(--black);
  margin: 0 0 16px;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-0 .p-var p {
  display: block;
  padding: 0;
  margin: 0 0 24px;
  font-size: 14px;
  line-height: 1.75;
  color: var(--black);
  width: 315px;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-0 .p-var ul {
  display: block;
  padding: 0;
  margin: 0 0 24px;
  font-size: 14px;
  line-height: 1.57;
  color: var(--black);
  width: 315px;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-0 .p-var ul li {
  position: relative;
  display: block;
  padding: 0;
  margin: 0 0 12px;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-0 .p-var ul li::before {
  content: '';
  width: 5px;
  height: 5px;
  background-color: #e9604a;
  border-radius: 50%;
  position: absolute;
  left: -11px;
  top: 10px;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-0 .p-var .button-ver2 {
  width: 217px;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-0 .p-var.stripe .button-ver2,
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-0 .p-var.native .button-ver2 {
  padding: 0;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-0 .p-var.stripe {
  background: url('./images/s1.png') no-repeat 428px 33px, url('./images/s2.png') no-repeat 432px 173px, url('./images/n_arrow.png') no-repeat 526px 162px;
  background-size: 124px auto, 80px auto, 18px auto;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-0 .p-var.native {
  overflow: hidden;
  min-height: 0;
  height: 43px;
  padding: 16px 35px 24px;
  background: url('./images/n1.png') no-repeat 428px 41px, url('./images/n2.png') no-repeat 432px 180px, url('./images/n_arrow.png') no-repeat 526px 168px;
  background-size: 124px auto, 94px auto, 18px auto;
  transition: height 200ms;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-0 .p-var.native .title {
  cursor: pointer;
  position: relative;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-0 .p-var.native .title::after {
  background: url('./images/ic-expand.svg') no-repeat center center;
  content: '';
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  transform: rotateZ(0deg);
  transition: transform 200ms;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-0 .p-var.native.show {
  min-height: inherit;
  height: 339px;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-0 .p-var.native.show .title::after {
  transform: rotateZ(90deg);
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-2 {
  padding: 0 264px 0 0;
  min-height: 401px;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-2 .title {
  margin: 0 0 24px;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-2 p {
  margin: 0 0 32px;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-2 .note {
  margin: 0px 0 75px;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-3 .title {
  margin: 0 0 6px;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-3 > p {
  margin: 0 0 50px;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-3 .stripe-box {
  text-align: center;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-3 .stripe-box .logo {
  position: relative;
  display: block;
  width: 120px;
  height: 120px;
  background: url('./images/stripelogo.png') no-repeat left top;
  background-size: contain;
  margin: 0 auto;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-3 .stripe-box .text-0 {
  font-size: 16px;
  line-height: 1.75;
  color: #666666;
  padding: 24px 0;
  width: 352px;
  margin: 0 auto;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-3 .stripe-box .text-0 a {
  color: #448af1;
  text-decoration: none;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-3 .stripe-box .text-0 a:hover {
  text-decoration: underline;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-3 .stripe-box .text-1 {
  display: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.75;
  color: var(--black);
  padding: 16px 0 6px;
  margin: 0 auto;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-3 .stripe-box .connect {
  display: block;
  margin: 0 auto;
  width: 160px;
  padding: 0;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-3 .stripe-box .disconnect {
  display: none;
  width: 100px;
  margin: 0 auto;
  font-size: 16px;
  line-height: 1.75;
  text-align: center;
  color: #448af1;
  cursor: pointer;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-3 .stripe-box .disconnect:hover {
  text-decoration: underline;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-3 .stripe-box.connected .logo::before {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  top: -8px;
  left: -8px;
  background: url('./images/ok.png') no-repeat;
  background-size: cover;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-3 .stripe-box.connected .text-0 {
  display: none;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-3 .stripe-box.connected .text-1 {
  display: block;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-3 .stripe-box.connected .connect {
  display: none;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-3 .stripe-box.connected .disconnect {
  display: block;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-whoops {
  position: static;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-whoops .whoops-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 352px;
  padding: 136px 0 68px;
  background: url('./images/pic_warning.png') no-repeat center top;
  background-size: 120px 120px;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-whoops .whoops-box p {
  font-size: 16px;
  line-height: 1.75;
  color: #666666;
  text-align: center;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-whoops .whoops-box p:first-child {
  margin: 0 0 8px;
  color: var(--black);
  font-weight: 600;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-1 > p {
  padding: 32px 0 0;
  font-size: 16px;
  line-height: 1.75;
  color: var(--black);
  margin: 0 0 24px;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-1 .form {
  padding-top: 20px;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-1 .form .line {
  margin: 0 0 24px;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-1 .form .line .control {
  display: inline-block;
  width: 284px;
  margin: 0 19px 0 0;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-1 .form .line .control:last-child {
  margin: 0;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-1 .form .line .control .label {
  font-size: 12px;
  line-height: 1.5;
  color: #666666;
  text-transform: uppercase;
  margin: 0 0 6px;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-1 .form .line .control .label .req {
  color: #e9604a;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-1 .form .line .control .input-box input {
  width: 100%;
  height: 36px;
  line-height: 34px;
  padding: 0 16px;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-1 .form .line:first-child .control {
  width: 100%;
  margin: 0 8px 0 0;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-1 .form .line:last-child {
  padding: 8px 0 0;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-1 .form .line .white-rounded {
  display: inline-block;
  vertical-align: top;
  background-color: var(--greyLight20);
  margin: 0 12px 0 0;
  padding: 0 24px;
  height: 36px;
  line-height: 34px;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-1 .form .line .r-col-note {
  display: inline-block;
  vertical-align: top;
  width: 360px;
  height: 32px;
  font-size: 12px;
  line-height: 1.33;
  color: var(--black);
  padding: 3px 0 0;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-1 .form .line .r-col-note a {
  text-decoration: none;
  color: #448af1;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state.state-step-1 .form .line .r-col-note a:hover {
  text-decoration: underline;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state .img {
  display: block;
  position: absolute;
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state .img.img1 {
  top: 52px;
  right: 0;
  width: 240px;
  height: 189px;
  background-image: url('./images/pic_payments_01.png');
}
.popup.wizard-payments .scroll-box div.window .wizard-content .state .img.img2 {
  top: 272px;
  right: 0;
  width: 240px;
  height: 138px;
  background-image: url('./images/pic_payments_02.png');
}
.popup.wizard-payments .scroll-box div.window .wizard-content .loader {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  pointer-events: none;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: var(--white);
  transition: opacity 200ms;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .loader .loader-box {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 352px;
  padding: 80px 0 0;
  background: url('./images/spinner_b.svg') no-repeat center top;
  background-size: 64px 64px;
  transition: display 0ms 200ms;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .loader .loader-box p {
  font-size: 16px;
  line-height: 1.75;
  color: #666666;
  text-align: center;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .loader .loader-box p:first-child {
  margin: 0 0 8px;
  color: var(--black);
  font-weight: 600;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .loader.act {
  opacity: 1;
  pointer-events: auto;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .loader.act .loader-box {
  display: block;
  transition: display 0ms 0ms;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .footer {
  padding: 0 24px !important;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .footer .button-ver2 {
  display: inline-block;
  margin: 0 0 0 12px;
}
.popup.wizard-payments .scroll-box div.window .wizard-content .footer .button-ver2.left {
  float: left;
  margin: 16px 0 0;
}
.popup.wizard-payments .scroll-box div.window .close {
  z-index: 5;
}
