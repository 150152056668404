.art-mini-dropdown {
  display: inline-block;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
}
.art-mini-dropdown span {
  display: inline-block;
  color: #448af1;
  cursor: pointer;
}
.art-mini-dropdown span::after {
  content: ' ▾';
}
.art-mini-dropdown ul {
  position: absolute;
  margin: 0;
  padding: 0;
  top: -2px;
  left: -13px;
  z-index: 100000;
  overflow: hidden;
  border-radius: 7px;
  background-color: var(--white);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px rgba(0, 0, 0, 0.24);
  display: none;
}
.art-mini-dropdown ul:hover li {
  background-color: var(--white) !important;
}
.art-mini-dropdown ul li {
  display: block;
  height: 28px;
  line-height: 28px;
  padding: 0 12px;
  margin: 0;
  cursor: pointer;
  white-space: nowrap;
  color: var(--black);
}
.art-mini-dropdown ul li:first-child::after {
  content: ' ▾';
}
.art-mini-dropdown ul li:hover {
  background-color: var(--greyLight20) !important;
}
.art-mini-dropdown ul li.act {
  background-color: var(--greyLight20);
}
.art-mini-dropdown.open ul {
  display: block;
}
