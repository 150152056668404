art-one-domain-whitelisted {
  display: block;
}
art-one-domain-whitelisted > div {
  position: relative;
}
art-one-domain-whitelisted .tooltip-whitelist-error {
  width: 280px;
}
art-one-domain-whitelisted .tooltip-whitelist-error .tooltip-inner {
  height: auto !important;
  padding: 5px 8px;
}
art-one-domain-whitelisted .tooltip-whitelist-error a {
  color: var(--white) !important;
  text-decoration: underline !important;
}
art-one-domain-whitelisted .tooltip-whitelist-error a:hover {
  text-decoration: none !important;
}
art-one-domain-whitelisted .status {
  position: absolute;
  top: 1px;
  right: 1px;
  width: 36px;
  height: 34px;
  border-radius: 5px;
  background: url('../images/ic_ok.svg') no-repeat center center, linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #ffffff 30%, #ffffff 100%);
}
