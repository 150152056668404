body > .popup .disconnect-pro-window {
  width: 512px;
  padding: 25px 25px 100px;
}
body > .popup .disconnect-pro-window .content p.title {
  color: var(--black);
  margin-bottom: 15px;
}
body > .popup .disconnect-pro-window .content p {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 1.75;
  color: #666666;
}
body > .popup .disconnect-pro-window .content p.modal-description span {
  white-space: nowrap;
}
body > .popup .disconnect-pro-window .content p.modal-description a {
  text-decoration: none;
  color: #e9604a;
}
body > .popup .disconnect-pro-window .content p.modal-description a:hover {
  opacity: 0.9;
}
body > .popup .disconnect-pro-window .content .footer {
  height: auto;
  text-align: left;
  line-height: 1;
  padding: 26px 26px;
}
body > .popup .disconnect-pro-window .content .footer .button-ver2.orange-rounded {
  display: inline-block;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  margin: 0 8px 0 0;
}
body > .popup .disconnect-pro-window .content .footer .button-ver2.orange-rounded:hover {
  box-shadow: none;
}
body > .popup .disconnect-pro-window .content .footer .white-rounded {
  display: inline-block;
  width: 140px;
  height: 36px;
  line-height: 34px;
  white-space: nowrap;
}
