.popup.setup-autoreply-rule {
  overflow: auto;
}
.popup.setup-autoreply-rule .back {
  position: fixed;
}
.popup.setup-autoreply-rule .scroll-box {
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup.setup-autoreply-rule .window {
  width: 512px;
  height: auto;
  padding: 24px;
  overflow: visible;
  position: static;
  top: initial !important;
  left: initial !important;
  transform: scale(1, 1) !important;
}
.popup.setup-autoreply-rule .window .footer {
  border: none;
  position: relative;
  height: auto;
  line-height: 0;
  padding: 0;
  margin-top: 20px;
}
.content_no-any-post {
  min-height: 528px;
}
.content_no-all-comments {
  min-height: 528px;
}
.content_no-any-post-no-all-comments {
  min-height: 610px;
}
.cf-field-discount[suggest-template-multiline] .wrap-mask {
  padding: 3px 10px;
}
.cf-field-discount[suggest-template-multiline] .wrap-mask .mask {
  font-size: 14px;
}
.cf-field-discount__discount {
  position: absolute;
  right: 0;
  bottom: 1px;
  text-align: center;
  font-size: 10px;
  color: var(--white);
  transition: opacity 100ms;
  line-height: 14px;
  padding: 0 4px;
  height: 14px;
  border-radius: 8px;
  background-color: #5dc991;
}
.setup-autoreply-rule__description {
  font-size: 14px;
  line-height: 1.43;
  color: #767676;
}
.setup-autoreply-rule__form-row {
  margin-bottom: 22px;
  transition: all 0.2s;
}
.setup-autoreply-rule_button:hover {
  border-color: rgba(0, 0, 0, 0.17) rgba(0, 0, 0, 0.17) rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.17) !important;
}
.setup-autoreply-rule_button:focus {
  border-color: rgba(0, 0, 0, 0.17) rgba(0, 0, 0, 0.17) rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.17) !important;
}
.setup-autoreply-rule__form-row_hidden {
  max-height: 0;
  margin: 0;
}
.setup-autoreply-rule__field-title {
  font-size: 12px;
  line-height: 2;
  color: #666666;
  display: block;
  text-transform: uppercase;
}
.cf-radio-button {
  -webkit-user-select: none;
          user-select: none;
  font-size: 14px;
  line-height: 1.43;
  color: var(--black);
  display: inline-block;
  margin-right: 22px;
  cursor: pointer;
}
.cf-radio-button:last-child {
  margin-right: 0;
}
.cf-radio-button__radio-input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0;
}
.cf-radio-button__radio-input + .cf-radio-button__radio-fake {
  display: inline-block;
  position: relative;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: var(--white);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px rgba(0, 0, 0, 0.1);
  margin: 0 8px 0 0;
  vertical-align: -2px;
}
.cf-radio-button__radio-input:checked + .cf-radio-button__radio-fake:after {
  content: ' ';
  z-index: 5;
  top: 3px;
  left: 3px;
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #448af1;
  border-radius: 50%;
}
.cf-radio-button__radio-input:focus + .cf-radio-button__radio-fake {
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.16);
}
.cf-radio-button__radio-fake {
  display: inline-block;
  position: relative;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: var(--white);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px rgba(0, 0, 0, 0.1);
  margin: 0 8px 0 0;
  vertical-align: -1px;
}
.cf-textarea {
  width: 100%;
  min-height: 58px;
  border-radius: 7px;
  background-color: rgba(0, 0, 0, 0.01);
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px rgba(0, 0, 0, 0.12);
  padding: 5px 10px;
  box-sizing: border-box;
  font-size: 14px;
  color: var(--black);
  resize: none;
  display: block;
}
.cf-textarea_error:not(:focus) {
  background-color: rgba(233, 96, 74, 0.02);
  box-shadow: inset 0 1px 3px 0 rgba(81, 0, 0, 0.08);
  border: solid 1px #e9604a;
  color: #e9604a;
}
.cf-textarea_error:not(:focus)::-webkit-input-placeholder {
  color: #e9604a;
}
.cf-textarea_error:not(:focus)::-moz-placeholder {
  color: #e9604a;
}
.cf-textarea_error:not(:focus):-moz-placeholder {
  color: #e9604a;
}
.cf-textarea_error:not(:focus):-ms-input-placeholder {
  color: #e9604a;
}
.cf-input-field {
  position: relative;
}
.cf-input-status-sticker {
  content: '';
  width: 24px;
  height: 24px;
  position: absolute;
  right: 4px;
  top: 50%;
  cursor: pointer;
  background: url('../../../assets2/images/ic_error.svg') no-repeat center center;
  background-size: cover;
  display: block;
  margin-top: -11px;
}
