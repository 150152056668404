blocks-suggest-sequences {
  display: block;
  width: 100%;
  /*

  */
  /*

  */
}
blocks-suggest-sequences ul,
blocks-suggest-sequences li,
blocks-suggest-sequences p,
blocks-suggest-sequences span {
  list-style: none;
  margin: 0;
  padding: 0;
}
blocks-suggest-sequences .bd-area {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 36px;
  background-color: rgba(0, 0, 0, 0.01);
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 7px;
  overflow-x: auto;
}
blocks-suggest-sequences .bd-area > div {
  flex: 0 1 auto;
}
blocks-suggest-sequences .bd-area__selected-blocks {
  display: block;
  width: auto;
}
blocks-suggest-sequences .bd-area__selected-blocks ul {
  display: flex;
  align-items: center;
}
blocks-suggest-sequences .bd-area__selected-blocks li {
  display: block;
  max-width: 180px;
  position: relative;
  background-color: #e1e1e1;
  border: solid 1px rgba(0, 0, 0, 0.21);
  border-radius: 7px;
  padding: 0 8px;
}
blocks-suggest-sequences .bd-area__selected-blocks li:first-child {
  margin-left: 6px;
}
blocks-suggest-sequences .bd-area__selected-blocks li:not(:last-child) {
  margin-right: 8px;
}
blocks-suggest-sequences .bd-area__selected-blocks li p {
  max-width: 100%;
  color: var(--black);
  line-height: 28px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
blocks-suggest-sequences .bd-area__selected-blocks li span {
  display: block;
  height: 20px;
  width: 20px;
  position: absolute;
  right: -10px;
  top: -4px;
  background-color: var(--white);
  border-radius: 50%;
  cursor: pointer;
  transform: scale(0);
  transition: transform 0.2s ease;
}
blocks-suggest-sequences .bd-area__selected-blocks li span:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: url('../../../../assets2/images/ic_trash_mini.svg');
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: center;
  opacity: 0.5;
}
blocks-suggest-sequences .bd-area__selected-blocks li span:hover:before {
  opacity: 1;
}
blocks-suggest-sequences .bd-area__selected-blocks li:hover span {
  transform: scale(1);
}
blocks-suggest-sequences .bd-area__bd-input {
  position: relative;
  width: 100%;
  min-width: 280px;
}
blocks-suggest-sequences .bd-area__bd-input input {
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
}
blocks-suggest-sequences .bd-input__bd-items {
  width: 100%;
  max-width: 320px;
  max-height: 250px;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: var(--zindex-dropdown);
  background-color: var(--white);
  border-radius: 7px;
  box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.16);
  overflow-y: auto;
  opacity: 0;
  transition: opacity 0.2s ease;
}
blocks-suggest-sequences .bd-input__bd-items.active {
  opacity: 1;
}
blocks-suggest-sequences .bd-input__bd-items li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  margin: 0;
  padding: 0 16px;
  cursor: pointer;
}
blocks-suggest-sequences .bd-input__bd-items li:hover,
blocks-suggest-sequences .bd-input__bd-items li:active {
  background-color: #f1f2f2;
}
blocks-suggest-sequences .bd-input__bd-items li p {
  width: auto;
  max-width: 70%;
  font-size: 14px;
  line-height: 18px;
  color: var(--black);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}
blocks-suggest-sequences .bd-input__bd-items li p:last-child {
  color: var(--grey);
  padding-left: 10px;
}
/*
  MODIFICATIONS
*/
div.plugin-modules .card .content .l-col .line .bd-area__bd-input input {
  background-color: transparent;
  border: none;
}
div.plugin-modules .card .content .l-col .line .bd-input__bd-items p {
  color: var(--black);
  font-size: 14px;
  white-space: nowrap;
  margin: 0;
}
div.plugin-modules .card .content .l-col .line .bd-input__bd-items p:last-child {
  color: var(--grey);
}
div.plugin-modules .card .content .l-col .line .bd-area__selected-blocks p {
  color: var(--black);
  font-size: 16px;
  line-height: 26px;
  white-space: nowrap;
  margin: 0;
}
