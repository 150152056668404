body > .popup .window.cancel-payments-window {
  width: 512px;
  padding: 25px 25px 100px;
}
body > .popup .window.cancel-payments-window .content p.title {
  color: var(--black);
  margin-bottom: 15px;
}
body > .popup .window.cancel-payments-window .content p {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 1.75;
  color: #666666;
}
body > .popup .window.cancel-payments-window .content p.modal-description a {
  text-decoration: none;
  color: #e9604a;
}
body > .popup .window.cancel-payments-window .content p.modal-description a:hover {
  opacity: 0.9;
}
body > .popup .window.cancel-payments-window .content .modal-description--monthly-fee {
  margin: 15px auto 10px;
}
body > .popup .window.cancel-payments-window .content .modal-description--monthly-fee p {
  font-size: 32px;
  line-height: 1;
  color: #e9604a;
}
body > .popup .window.cancel-payments-window .content .modal-description--monthly-fee p span {
  display: block;
  font-size: 12px;
  line-height: 28px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
body > .popup .window.cancel-payments-window .content .footer {
  height: auto;
  text-align: left;
  line-height: 1;
  padding: 26px 26px;
}
body > .popup .window.cancel-payments-window .content .footer .button-ver2.orange-rounded {
  display: inline-block;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
}
body > .popup .window.cancel-payments-window .content .footer .button-ver2.orange-rounded:hover {
  box-shadow: none;
}
