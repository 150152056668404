.art-baloons-input {
  position: relative;
  min-height: 36px;
  border-radius: 7px;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.01);
  transition: box-shadow 100ms, border-color 100ms;
  cursor: text;
  padding: 4px 0 0 8px;
  line-height: 26px;
}
.art-baloons-input.error {
  box-shadow: inset 0 1px 3px 0 rgba(81, 0, 0, 0.07);
  border: solid 1px #e9604a;
  background-color: #fff5f4;
}
.art-baloons-input.error .placeholder {
  color: #e9604a;
}
.art-baloons-input.error .placeholder span {
  background-image: url(../images/ic_enter_red.svg);
  opacity: 1;
}
.art-baloons-input .placeholder {
  display: none;
  position: absolute;
  top: 4px;
  left: 0;
  font-size: 16px;
  line-height: 26px;
  color: var(--grey);
  padding: 0 10px 4px 15px;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}
.art-baloons-input .placeholder span {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  background: url(../images/ic_enter.svg) no-repeat center center;
  background-size: cover;
  opacity: 0.3;
}
.art-baloons-input.empty .placeholder {
  display: block;
}
.art-baloons-input .green {
  display: inline-block;
  position: relative;
  vertical-align: top;
  line-height: 26px;
  min-height: 26px;
  max-width: 98.5%;
  word-wrap: break-word;
  border-radius: 18px;
  background-color: var(--green);
  padding: 0 8px;
  font-size: 16px;
  color: var(--white);
  margin: 0 8px 4px 0;
}
.art-baloons-input .green.empty:last-child {
  background-color: transparent;
}
.art-baloons-input .green.empty:last-child span {
  color: var(--black);
  outline: 1px solid rgba(0, 0, 0, 0);
}
.art-baloons-input .green.empty:last-child .trash-popup {
  display: none;
}
.art-baloons-input .green span:not(.trash-popup) {
  outline: 1px solid rgba(0, 0, 0, 0);
  display: inline-block;
  min-width: 10px;
  max-width: 100%;
  line-height: 1.5;
  -webkit-user-select: auto;
  user-select: all;
}
.art-baloons-input .green span:not(.trash-popup):empty::before {
  display: inline-block;
  content: '_';
  color: rgba(0, 0, 0, 0);
}
.art-baloons-input .green .trash-popup {
  top: -6px;
  right: -6px;
  left: auto;
}
.art-baloons-input .green:hover .trash-popup {
  opacity: 1;
  transform: scale(1, 1);
}
.art-baloons-input .green::after {
  content: ',';
  width: 18px;
  position: absolute;
  bottom: 0;
  right: -8px;
  font-size: 16px;
  line-height: 1.5;
  color: var(--grey);
  padding: 0 2px 0 0;
  height: 26px;
  text-align: right;
}
.art-baloons-input .green:last-child::after {
  display: none;
}
.art-baloons-input .green .discount {
  position: absolute;
  right: 10px;
  bottom: -8px;
  text-align: center;
  font-size: 10px;
  color: var(--white);
  opacity: 0;
  line-height: 14px;
  padding: 0 4px;
  height: 14px;
  border-radius: 8px;
  background-color: #5dc991;
  box-shadow: 0 0 0 1px var(--white);
  z-index: 1000;
  transform: translateX(100%);
}
.art-baloons-input .green .line:not(:empty):focus ~ .discount {
  opacity: 1;
}
.art-baloons-input .current {
  display: inline-block;
  position: relative;
  vertical-align: top;
  line-height: 26px;
  min-height: 26px;
  max-width: 98.5%;
  word-wrap: break-word;
  outline: none;
  padding: 0;
  font-size: 16px;
  color: var(--black);
  margin: 0 8px 4px 0;
}
.art-baloons-input .current:empty::before {
  content: '_';
  color: rgba(0, 0, 0, 0);
}
