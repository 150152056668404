.art-radio {
  display: inline-block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}
.art-radio input {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  z-index: 10;
  opacity: 0;
}
.art-radio label {
  cursor: pointer;
}
.art-radio label > span {
  display: inline-block;
  width: 24px;
  height: 24px;
  position: relative;
  vertical-align: bottom;
}
.art-radio label > span::after {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  width: 14px;
  height: 14px;
  background-color: var(--white);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px rgba(0, 0, 0, 0.21);
  border-radius: 50%;
  z-index: 1;
}
.art-radio label > span::before {
  content: '';
  position: absolute;
  top: 8px;
  left: 8px;
  width: 8px;
  height: 8px;
  background-color: #448af2;
  border-radius: 50%;
  z-index: 2;
  opacity: 0;
  transition: opacity 150ms;
}
.art-radio label input:checked + span::before {
  opacity: 1;
}
