.popup.user-property {
  overflow: auto;
}
.popup.user-property.openHelp .back {
  min-height: 900px;
}
.popup.user-property.openHelp .scroll-box {
  min-height: 900px;
}
.popup.user-property .scroll-box div.window {
  width: 548px;
  overflow: visible;
  padding: 32px 32px 92px;
  top: 50%;
}
.popup.user-property .scroll-box div.window .content p.title {
  margin: 0 0 16px;
  position: relative;
  padding: 0 0 0 32px;
}
.popup.user-property .scroll-box div.window .content p.title::before {
  content: ' ';
  background: url('./images/ic_user_variable.svg') no-repeat center center;
  background-size: contain;
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  left: 0;
  top: 0;
}
.popup.user-property .scroll-box div.window .content .help {
  height: 108px;
  background-color: var(--greyLight20);
  padding: 16px 32px;
  overflow: hidden;
  transition: height cubic-bezier(0.22, 0.61, 0.36, 1) 200ms;
  margin: 0 -32px;
}
.popup.user-property .scroll-box div.window .content .help p {
  margin: 0 0 24px;
  font-size: 14px;
  line-height: 2;
  color: var(--black);
}
.popup.user-property .scroll-box div.window .content .help p:first-child {
  line-height: 1.71;
}
.popup.user-property .scroll-box div.window .content .help p .img {
  display: block;
  height: 76px;
  background: url('./images/pic_user_variable_explain.png') no-repeat;
  background-size: contain;
}
.popup.user-property .scroll-box div.window .content .help p a {
  line-height: 1.5;
  color: #448af1;
  text-decoration: none;
}
.popup.user-property .scroll-box div.window .content .help p a:hover {
  text-decoration: underline;
}
.popup.user-property .scroll-box div.window .content .help p:last-child {
  margin: 0;
}
.popup.user-property .scroll-box div.window .content .help p .ico-blue {
  display: inline-block;
  height: 26px;
  line-height: 24px;
  border-radius: 13px;
  border: solid 1px #448af1;
  font-size: 14px;
  color: #448af1;
  padding: 0 8px;
}
.popup.user-property .scroll-box div.window .content .help p .ico-brown {
  display: inline-block;
  height: 24px;
  line-height: 24px;
  border-radius: 13px;
  font-size: 14px;
  color: var(--white);
  padding: 0 8px;
  background-color: #906d60;
}
.popup.user-property .scroll-box div.window .content .help p .ico-brown::before {
  content: '{{';
  font-size: 14px;
  position: relative;
  left: -2px;
  top: -2px;
  color: #e0995d;
}
.popup.user-property .scroll-box div.window .content .help p .ico-brown::after {
  content: '}}';
  font-size: 14px;
  position: relative;
  left: 2px;
  top: -2px;
  color: #e0995d;
}
.popup.user-property .scroll-box div.window .content .help.act {
  height: 396px;
}
.popup.user-property .scroll-box div.window .content .var-box {
  position: relative;
}
.popup.user-property .scroll-box div.window .content .help-switch {
  font-size: 16px;
  line-height: 1.5;
  color: #448af1;
  padding: 8px 0 24px;
}
.popup.user-property .scroll-box div.window .content .help-switch span {
  position: relative;
  cursor: pointer;
}
.popup.user-property .scroll-box div.window .content .help-switch span:hover {
  text-decoration: underline;
}
.popup.user-property .scroll-box div.window .content .help-switch span::after {
  font-size: 18px;
  position: absolute;
  right: -15px;
  top: -4px;
}
.popup.user-property .scroll-box div.window .content .help-switch span.show::after {
  content: '▾';
}
.popup.user-property .scroll-box div.window .content .help-switch span.hide::after {
  content: '▴';
}
.popup.user-property .scroll-box div.window .content .create {
  text-align: right;
  position: relative;
}
.popup.user-property .scroll-box div.window .content .create > input {
  width: 100%;
  height: 36px;
  line-height: 34px;
  border-radius: 3px;
  background-color: var(--white);
  box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px rgba(0, 0, 0, 0.13);
  font-size: 16px;
  color: var(--black);
  padding: 0 32px 0 10px;
  text-indent: 16px;
}
.popup.user-property .scroll-box div.window .content .create > .close {
  position: absolute;
  right: 8px;
  top: 6px;
  background-size: 15px auto;
}
.popup.user-property .scroll-box div.window .content .create .variable-mask-wrapper {
  height: 24px;
  position: relative;
  width: 444px;
  top: -30px;
  left: 8px;
  overflow-x: hidden;
  pointer-events: none;
}
.popup.user-property .scroll-box div.window .content .create .variable-mask {
  display: inline-block;
  text-align: left;
  pointer-events: none;
  color: transparent;
  float: left;
  padding: 0 5px;
  border-radius: 13px;
}
.popup.user-property .scroll-box div.window .content .create .variable-mask:before {
  content: '{{';
  color: var(--black);
  margin-right: 2px;
}
.popup.user-property .scroll-box div.window .content .create .variable-mask:after {
  content: '}}';
  color: var(--black);
  margin-left: 2px;
}
.popup.user-property .scroll-box div.window .content .create .variable-mask.show-mask {
  background-color: #906d60;
  color: var(--white);
  padding: 0 8px;
}
.popup.user-property .scroll-box div.window .content .create .variable-mask.show-mask::before {
  content: '{{';
  font-size: 16px;
  position: relative;
  left: -2px;
  top: -1px;
  color: #e0995d;
  margin-right: 0px;
}
.popup.user-property .scroll-box div.window .content .create .variable-mask.show-mask::after {
  content: '}}';
  font-size: 16px;
  position: relative;
  left: 2px;
  top: -1px;
  color: #e0995d;
  margin-left: 0px;
}
.popup.user-property .scroll-box div.window .content .property-selector {
  height: 36px;
  line-height: 36px;
}
.popup.user-property .scroll-box div.window .content .property-selector .or {
  display: inline-block;
  position: relative;
  font-size: 14px;
  line-height: 1.71;
  text-align: center;
  color: var(--grey);
  padding: 0 4px;
}
.popup.user-property .scroll-box div.window .content .property-selector .go-create {
  vertical-align: top;
}
.popup.user-property .scroll-box div.window .content .property-selector .selector-ver2 {
  display: inline-block;
  width: 244px;
  vertical-align: top;
}
.popup.user-property .scroll-box div.window .content .property-selector .selector-ver2 .label.placeholder {
  overflow-x: hidden;
}
.popup.user-property .scroll-box div.window .content .property-selector .selector-ver2 .variable-mask {
  display: inline-block;
  height: 26px;
  border-radius: 13px;
  background-color: #906d60;
  padding: 0 10px;
  font-size: 16px;
  line-height: 26px;
  color: var(--white);
}
.popup.user-property .scroll-box div.window .content .property-selector .selector-ver2 .variable-mask::before {
  content: '{{';
  font-size: 16px;
  position: relative;
  left: -2px;
  top: -1px;
  color: #e0995d;
}
.popup.user-property .scroll-box div.window .content .property-selector .selector-ver2 .variable-mask::after {
  content: '}}';
  font-size: 16px;
  position: relative;
  left: 2px;
  top: -1px;
  color: #e0995d;
}
.popup.user-property .scroll-box div.window .content .property-selector .selector-ver2 ul {
  width: 260px;
}
.popup.user-property .scroll-box div.window .content .property-selector .selector-ver2 ul li span {
  display: inline-block;
  height: 26px;
  border-radius: 13px;
  background-color: #906d60;
  padding: 0 10px;
  font-size: 16px;
  line-height: 26px;
  color: var(--white);
}
.popup.user-property .scroll-box div.window .content .property-selector .selector-ver2 ul li span::before {
  content: '{{';
  font-size: 16px;
  position: relative;
  left: -2px;
  top: -1px;
  color: #e0995d;
}
.popup.user-property .scroll-box div.window .content .property-selector .selector-ver2 ul li span::after {
  content: '}}';
  font-size: 16px;
  position: relative;
  left: 2px;
  top: -1px;
  color: #e0995d;
}
