.art-big-dropdown {
  position: relative;
  display: inline-block;
  height: 36px;
  width: 206px;
  vertical-align: middle;
}
.art-big-dropdown::after {
  position: absolute;
  content: '';
  top: 6px;
  right: 6px;
  z-index: 5;
  width: 24px;
  height: 24px;
  background: url('../images/ic_dropdown.svg') no-repeat center center;
  pointer-events: none;
}
.art-big-dropdown .selected {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 36px;
  border-radius: 7px;
  background-color: var(--white);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  border: solid 1px rgba(0, 0, 0, 0.12);
  z-index: 2;
  padding: 0 36px 0 16px;
  color: var(--black);
  font-size: 16px;
  line-height: 34px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: box-shadow 80ms ease, border-color 80ms ease;
  cursor: pointer;
}
.art-big-dropdown .selected.placeholder {
  color: var(--grey);
}
.art-big-dropdown .selected:hover:not(.locked) {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px rgba(0, 0, 0, 0.16);
}
.art-big-dropdown.locked {
  pointer-events: none;
  cursor: default;
}
.art-big-dropdown ul {
  position: absolute;
  z-index: 3;
  left: 0;
  top: -3px;
  min-width: 100%;
  padding: 0;
  margin: 0;
  pointer-events: none;
  max-height: 40px;
  opacity: 0;
  border-radius: 7px;
  overflow: hidden;
  background-color: var(--white);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px rgba(0, 0, 0, 0.12);
  transition: max-height 150ms cubic-bezier(0.22, 0.61, 0.36, 1), opacity 200ms cubic-bezier(0.22, 0.61, 0.36, 1);
}
.art-big-dropdown ul li {
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  font-size: 16px;
  padding: 0 36px 0 16px;
  transition: background-color 80ms ease;
}
.art-big-dropdown ul li.disabled {
  pointer-events: none;
  color: var(--grey);
}
.art-big-dropdown ul li.act {
  background-color: #f1f2f2;
}
.art-big-dropdown ul li:hover {
  background-color: #f1f2f2 !important;
}
.art-big-dropdown ul:hover li.act {
  background-color: transparent;
}
.art-big-dropdown.open .selected {
  pointer-events: none;
}
.art-big-dropdown.open ul {
  max-height: 300px;
  opacity: 1;
  pointer-events: auto;
  top: -3px;
}
