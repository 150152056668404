.button-popup-editor {
  position: absolute;
  width: 200px;
  max-height: 0;
  left: 0;
  top: 0;
  z-index: var(--zindex-popover);
  transform: translateX(-50%);
  opacity: 0;
  background-color: var(--white);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0);
  border: solid 1px rgba(0, 0, 0, 0);
  border-radius: 6px;
  transition: width cubic-bezier(0.2, 0.7, 0.5, 1) 100ms, max-height cubic-bezier(0.2, 0.7, 0.5, 1) 130ms, box-shadow cubic-bezier(0.2, 0.7, 0.5, 1) 100ms, border-color cubic-bezier(0.2, 0.7, 0.5, 1) 100ms, opacity 50ms 50ms;
  pointer-events: none;
}
.button-popup-editor .title-box {
  width: 100%;
  padding: 12px 0 0;
  height: 60px;
  text-align: center;
}
.button-popup-editor .title-box .title-resize-box {
  overflow: hidden;
  text-align: left;
  padding: 0 16px;
  position: relative;
  margin: 0;
  display: inline-block;
  vertical-align: top;
  cursor: text;
  line-height: 34px;
  width: 0px;
  opacity: 0;
  transition: width cubic-bezier(0.2, 0.7, 0.5, 1) 100ms 0ms, opacity 100ms 0ms;
  -webkit-transition: width cubic-bezier(0.2, 0.7, 0.5, 1) 100ms 0ms, opacity 100ms 0ms;
}
.button-popup-editor .title-box .title-resize-box .title {
  display: inline-block;
  font-size: 17px;
  overflow: hidden;
  max-width: 100%;
  vertical-align: top;
  color: #448af1;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: auto !important;
  user-select: auto !important;
}
.button-popup-editor .title-box .title-resize-box .title::before {
  -webkit-user-select: none;
  user-select: none;
}
.button-popup-editor .title-box .title-resize-box .title:empty:not(:focus)::before {
  color: #e9604a;
}
.button-popup-editor .title-box .title-resize-box .title br {
  content: '';
}
.button-popup-editor .title-box .title-resize-box .title br::after {
  content: ' ';
}
.button-popup-editor .title-box .title-resize-box.not-been-change {
  background-color: rgba(0, 0, 0, 0.01) !important;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.08) !important;
  border: solid 1px rgba(0, 0, 0, 0.24) !important;
}
.button-popup-editor .title-box .title-resize-box.not-been-change .title::before {
  color: var(--grey) !important;
}
.button-popup-editor .title-box .title-resize-box.share-title-box {
  background-color: rgba(0, 0, 0, 0) !important;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0) !important;
  border: solid 1px rgba(0, 0, 0, 0) !important;
}
.button-popup-editor .title-box .title-resize-box.buy-title-box {
  background-color: rgba(0, 0, 0, 0) !important;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0) !important;
  border: solid 1px rgba(0, 0, 0, 0) !important;
  padding: 0 10px;
  width: 252px;
  line-height: 34px;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.button-popup-editor .title-box .title-resize-box.buy-title-box.empty {
  background-color: rgba(0, 0, 0, 0) !important;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0) !important;
  border: solid 1px rgba(0, 0, 0, 0) !important;
}
.button-popup-editor .title-box .title-resize-box.buy-title-box .buy-title {
  cursor: pointer;
  text-align: left;
  margin: -2px 0 0;
}
.button-popup-editor .title-box .title-resize-box.buy-title-box .buy-title .coast {
  display: inline-block;
  color: var(--black);
  font-size: 17px;
}
.button-popup-editor .title-box .title-resize-box.buy-title-box .buy-title .coast.error {
  color: #e9604a;
}
.button-popup-editor .title-box .title-resize-box.buy-title-box .buy-title .coast span {
  display: inline-block;
  text-overflow: ellipsis;
  border: none;
  outline: none;
  width: 130px;
  overflow: hidden;
  vertical-align: bottom;
}
.button-popup-editor .title-box .title-resize-box.buy-title-box .buy-title .buy-title-text {
  float: right;
  text-align: right;
  color: #448af1;
}
.button-popup-editor .title-box .title-resize-box.buy-title-box .buy-title .buy-title-text .button-ver2 {
  display: inline-block;
  vertical-align: -6px;
}
.button-popup-editor .title-box .title-resize-box .discount {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  text-align: center;
  font-size: 10px;
  color: var(--white);
  opacity: 0;
  transition: opacity 100ms;
  line-height: 14px;
  padding: 0 4px;
  height: 14px;
  border-radius: 8px;
  background-color: #5dc991;
}
.button-popup-editor .title-box .title-resize-box .share-title {
  color: #448af1;
  padding: 0 28px 0 0;
  margin: 0;
  text-align: center;
  font-size: 17px;
}
.button-popup-editor .title-box .title-resize-box .share-title .icon {
  display: inline-block;
  position: relative;
  vertical-align: -6px;
}
.button-popup-editor .title-box .title-resize-box .share-title .icon.ihide {
  opacity: 0;
  pointer-events: none;
}
.button-popup-editor .title-box .title-resize-box .title:focus + .discount {
  opacity: 1;
}
.button-popup-editor .title-box .preview {
  font-size: 12px;
  line-height: 1.17;
  color: var(--grey);
  text-align: center;
}
.button-popup-editor .title-box .preview .blocks {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
}
.button-popup-editor .title-box .preview .blocks::after {
  content: '';
  position: absolute;
  height: 14px;
  width: 14px;
  top: 0;
  right: 0;
  z-index: 10;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
}
.button-popup-editor .title-box .preview .blocks span {
  position: relative;
  display: inline-block;
  height: 14px;
  line-height: 14px;
  font-size: 12px;
  color: #767676;
  white-space: nowrap;
  border-radius: 3px;
  background-color: var(--greyLight20);
  padding: 0 2px;
  margin: 0 7px 0 0;
}
.button-popup-editor .title-box .preview .blocks span::after {
  content: ',';
  position: absolute;
  top: 0;
  right: -5px;
  font-size: 12px;
  color: #767676;
  width: 4px;
  text-align: right;
  height: 14px;
  line-height: 14px;
}
.button-popup-editor .title-box .preview .blocks span:last-child {
  margin: 0;
}
.button-popup-editor .title-box .preview .blocks span:last-child::after {
  display: none;
}
.button-popup-editor .title-box .preview .url {
  max-width: 230px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 auto;
  white-space: nowrap;
}
.button-popup-editor .title-box .preview .url span {
  display: inline-block;
  height: 13px;
  border-radius: 10px;
  background-color: #906d60;
  padding: 0 15px;
  font-size: 11px;
  line-height: 12px;
  color: var(--white);
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  vertical-align: middle;
}
.button-popup-editor .title-box .preview .url span::before,
.button-popup-editor .title-box .preview .url span::after {
  position: absolute;
  top: 0;
  font-size: 11px;
  color: #e0995d;
}
.button-popup-editor .title-box .preview .url span::before {
  left: 3px;
  content: '{{';
}
.button-popup-editor .title-box .preview .url span::after {
  right: 3px;
  content: '}}';
}
.button-popup-editor .title-box .preview .url span.system {
  background-color: #9e80bd;
}
.button-popup-editor .title-box .preview .url span.system:before,
.button-popup-editor .title-box .preview .url span.system:after {
  color: #e0e0e0;
}
.button-popup-editor .hide-box {
  width: 400px;
  pointer-events: none;
  opacity: 0;
  transform: translateY(-50px);
  transition: opacity 30ms 0ms, transform cubic-bezier(0.2, 0.7, 0.5, 1) 50ms 0ms;
}
.button-popup-editor .hide-box .state-selector {
  padding: 0;
  margin: 0 0 10px;
  display: block;
}
.button-popup-editor .hide-box .state-selector li {
  display: inline-block;
  position: relative;
  vertical-align: top;
  white-space: nowrap;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  font-size: 14px;
  line-height: 1.5;
  color: var(--black);
  transition: color 100ms, border-bottom-color 100ms;
  margin: 0 12px 0 0;
  padding: 0 0 0 20px;
}
.button-popup-editor .hide-box .state-selector li:last-child {
  margin: 0;
}
.button-popup-editor .hide-box .state-selector li::before {
  position: absolute;
  content: '';
  width: 16px;
  height: 16px;
  opacity: 0.3;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-position: left center;
  background-repeat: no-repeat;
}
.button-popup-editor .hide-box .state-selector li.blocks::before {
  background-image: url('../images/action-icons/ic_blocks_small.svg');
}
.button-popup-editor .hide-box .state-selector li.url::before {
  background-image: url('../images/action-icons/ic_link_small.svg');
}
.button-popup-editor .hide-box .state-selector li.submenu::before {
  background-image: url('../images/action-icons/ic_submenu.svg');
}
.button-popup-editor .hide-box .state-selector li.phone-call::before {
  background-image: url('../images/action-icons/ic_call_small.svg');
}
.button-popup-editor .hide-box .state-selector li:hover,
.button-popup-editor .hide-box .state-selector li.act {
  color: #e9604a;
}
.button-popup-editor .hide-box .state-selector li:hover::before,
.button-popup-editor .hide-box .state-selector li.act::before {
  opacity: 1;
}
.button-popup-editor .hide-box .state-selector li:hover.blocks::before,
.button-popup-editor .hide-box .state-selector li.act.blocks::before {
  background-image: url('../images/action-icons/ic_blocks_small_active.svg');
}
.button-popup-editor .hide-box .state-selector li:hover.url::before,
.button-popup-editor .hide-box .state-selector li.act.url::before {
  background-image: url('../images/action-icons/ic_link_small_active.svg');
}
.button-popup-editor .hide-box .state-selector li:hover.submenu::before,
.button-popup-editor .hide-box .state-selector li.act.submenu::before {
  background-image: url('../images/action-icons/ic_submenu_active.svg');
}
.button-popup-editor .hide-box .state-selector li:hover.phone-call::before,
.button-popup-editor .hide-box .state-selector li.act.phone-call::before {
  background-image: url('../images/action-icons/ic_call_small_active.svg');
}
.button-popup-editor .hide-box .state-selector li.act {
  border-bottom: 2px solid #e9604a;
}
.button-popup-editor .hide-box .states-box > div.url .webview-h-sel-box {
  padding: 22px 0 8px;
}
.button-popup-editor .hide-box .states-box > div.url .webview-h-sel-box .art-big-dropdown {
  float: right;
  margin: -6px 0;
}
.button-popup-editor .hide-box .states-box > div > p {
  margin: 0;
  padding: 6px 0;
  font-size: 16px;
  line-height: 1.5;
  color: #767676;
}
.button-popup-editor .hide-box .states-box > div .input-box {
  height: 36px;
  border-radius: 7px;
}
.button-popup-editor .hide-box .states-box > div .input-box .input {
  height: 34px;
  line-height: 34px;
  border: none;
  background-color: transparent;
  outline: none;
  width: 100%;
  padding: 0 16px;
}
.button-popup-editor .hide-box .states-box > div .input-url-box .art-textarea-expand,
.button-popup-editor .hide-box .states-box > div .input-url-box .art-textarea-expand.error:focus {
  border-radius: 7px;
  background-color: #fafafa;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px rgba(0, 0, 0, 0.09);
  color: var(--black);
  max-height: 72px;
}
.button-popup-editor .hide-box .states-box > div .input-url-box .art-textarea-expand::before,
.button-popup-editor .hide-box .states-box > div .input-url-box .art-textarea-expand.error:focus::before {
  color: var(--grey);
}
.button-popup-editor .hide-box .states-box > div .input-url-box .art-textarea-expand br,
.button-popup-editor .hide-box .states-box > div .input-url-box .art-textarea-expand.error:focus br {
  content: '';
}
.button-popup-editor .hide-box .states-box > div .input-url-box .art-textarea-expand br::after,
.button-popup-editor .hide-box .states-box > div .input-url-box .art-textarea-expand.error:focus br::after {
  content: ' ';
}
.button-popup-editor .hide-box .states-box > div .input-url-box .art-textarea-expand div,
.button-popup-editor .hide-box .states-box > div .input-url-box .art-textarea-expand.error:focus div {
  display: inline;
}
.button-popup-editor .hide-box .states-box > div .input-url-box .art-textarea-expand.error:not(:focus)::before {
  transition: color 60ms;
  color: #e9604a;
}
.button-popup-editor .hide-box .states-box > div.cf-menu p {
  color: #e9604a;
}
.button-popup-editor .hide-box .buttons {
  text-align: right;
}
.button-popup-editor .hide-box .buttons .button-ver2 {
  display: inline-block;
  vertical-align: top;
  height: 36px;
  width: 104px;
  line-height: 34px;
  margin: 0 0 0 12px;
}
.button-popup-editor .hide-box .buttons .button-ver2.orange-rounded {
  line-height: 36px;
}
.button-popup-editor.open {
  transition: width cubic-bezier(0.2, 0.7, 0.5, 1) 100ms, max-height cubic-bezier(0.2, 0.7, 0.5, 1) 130ms, box-shadow cubic-bezier(0.2, 0.7, 0.5, 1) 100ms, border-color cubic-bezier(0.2, 0.7, 0.5, 1) 100ms, opacity 50ms 0ms;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.13);
  border: solid 1px rgba(0, 0, 0, 0.09);
  max-height: 1000px;
  width: 400px;
  pointer-events: auto;
  opacity: 1;
}
.button-popup-editor.open .title-box .title-resize-box {
  width: 264px;
  opacity: 1;
  transition: width cubic-bezier(0.2, 0.7, 0.5, 1) 100ms 50ms, opacity 100ms 50ms;
  -webkit-transition: width cubic-bezier(0.2, 0.7, 0.5, 1) 100ms 50ms, opacity 100ms 50ms;
}
.button-popup-editor.open .hide-box {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
  transition: opacity 50ms 50ms, transform cubic-bezier(0.2, 0.7, 0.5, 1) 50ms 50ms;
}
.card-item .tooltip {
  z-index: 20;
}
