bot-select {
  display: block;
}
bot-select .selector {
  position: relative;
  color: var(--black);
  font-size: 0;
  height: 36px;
  vertical-align: top;
  border-radius: 7px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  left: 0;
  padding: 0 0 0 10px;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
bot-select .selector.disabled {
  pointer-events: none;
}
bot-select .selector.disabled .arrow {
  display: none;
}
bot-select .selector .arrow {
  position: relative;
  display: block;
  background: url('../../../../assets2/images/ic_dropdown.svg') no-repeat center center;
  width: 24px;
  height: 24px;
  opacity: 0.3;
  top: inherit;
  margin-left: 10px;
  margin-right: 6px;
  z-index: 11;
}
bot-select .selector:hover .arrow {
  opacity: 1;
}
bot-select .selector .bot-image {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: initial;
  margin: 0;
  display: block;
  flex-shrink: 0;
  flex-grow: 0;
}
bot-select .selector .label {
  white-space: nowrap;
  position: relative;
  display: flex;
  height: 36px;
  justify-content: center;
  width: 100%;
  align-items: center;
  overflow: hidden;
}
bot-select .selector .label a {
  text-decoration: none;
  color: var(--white);
}
bot-select .selector .label .current {
  position: relative;
  cursor: pointer;
  font-size: 14px;
  margin: 0;
  width: 100%;
  height: initial;
  display: block;
  padding: 0 0 0 10px;
  -webkit-user-select: none;
  user-select: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
bot-select .selector .label span {
  width: 100%;
  height: 100px;
}
bot-select .selector .label.one-bot {
  pointer-events: none;
}
bot-select .selector .label.one-bot .current:after {
  display: none;
}
bot-select .selector .options {
  position: absolute;
  z-index: 10;
  overflow: hidden;
  background-color: var(--white);
  border-radius: 7px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px rgba(0, 0, 0, 0.09);
  left: -1px;
  top: -1px;
  overflow-y: auto;
  max-height: 419px;
  pointer-events: none;
  opacity: 0;
  margin: 0 0 0 0px;
  padding: 36px 0 0;
  box-sizing: content-box;
  width: 100% !important;
}
bot-select .selector .options li {
  margin: 0;
  width: 100%;
  cursor: pointer;
  padding: 0 40px 0 10px;
  display: flex;
  align-items: center;
  height: 36px;
  box-sizing: border-box;
}
bot-select .selector .options li .bot-image-selector {
  position: relative;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0;
  pointer-events: none;
  display: block;
  flex-shrink: 0;
  flex-grow: 0;
}
bot-select .selector .options li .option-item-label {
  white-space: nowrap;
  position: relative;
  display: flex;
  height: 36px;
  justify-content: center;
  width: 100%;
  align-items: center;
  overflow: hidden;
}
bot-select .selector .options li a {
  position: relative;
  cursor: pointer;
  font-size: 14px;
  margin: 0;
  width: 100%;
  height: initial;
  display: block;
  padding: 0 0 0 10px;
  -webkit-user-select: none;
  user-select: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
bot-select .selector .options li.selected-bot {
  position: absolute;
  top: -1px;
}
bot-select .selector .options li.selected-bot a {
  overflow: hidden;
}
bot-select .selector .options li:hover {
  background-color: #f1f2f2;
}
bot-select .selector.active .options {
  top: -1px;
  pointer-events: auto;
  opacity: 1;
}
